// FIXME: Move to database-zod

export const SUPPORT_EMAIL = "support@procision.com";
export const SUPPORT_PHONE_NUMBER = "917-794-4955";

export const CANCELLATION_REASONS = [
  "Covid-19",
  "Patient sick",
  "Patient canceled",
  "Missing pre-auth",
  "Missing clearance",
  "Missing lab",
  "Surgeon canceled",
  "Facility canceled",
  "Meet anesth criteria",
  "Weather",
  "NPO",
  "Other",
] as const;
