import { createTRPCProxyClient, httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import { toast } from "react-toastify";
import superjson from "superjson";

import { type AppRouter } from "../server/trpc/router/_app";
import type { inferReactQueryProcedureOptions } from "@trpc/react-query";

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  // if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

const trpcConfig = {
  config() {
    return {
      transformer: superjson,
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            onError(err: unknown) {
              if (window && err instanceof Error) toast.error(err.message);
            },
          },
          mutations: {
            onError(err: unknown) {
              if (window && err instanceof Error) toast.error(err.message);
            },
          },
        },
      },
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
          maxURLLength: 2083, // tRPC's published "suitable size"
        }),
      ],
    };
  },
  ssr: false,
};
export const client = createTRPCProxyClient<AppRouter>(trpcConfig.config());

export const trpc = createTRPCNext<AppRouter>(trpcConfig);

/**
 * Inference helper for react-query options
 * @example type HelloOptions = ReactQueryOptions['example']['hello']
 */
export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
/**
 * Inference helper for inputs
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>;
/**
 * Inference helper for outputs
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>;
