import { PermissionDeniedError, type AppAbility } from "@procision-software/auth";
import { type PrismaClient } from "@procision-software/database";
import adjustmentsJson from "~/../prisma/seed/blob/adjustment_codes.json";

// Define a unique symbol
// declare const BillingAdjustmentIdTag: unique symbol;

// Create a tagged type
// export type BillingAdjustmentId = string & { readonly tag: typeof BillingAdjustmentIdTag };
export type BillingAdjustmentId = string;

// Function to tag a string
export function toBillingAdjustmentId(id: string): BillingAdjustmentId {
  // return id as BillingAdjustmentId;
  return id;
}

export function billingAdjustmentIdToString(id: BillingAdjustmentId): string {
  return id.toString();
}

export async function seedAdjustments(prisma: PrismaClient, ability: AppAbility) {
  if (!ability.can("manage", "BillingAdjustment")) {
    throw new PermissionDeniedError();
  }

  for (const adj of adjustmentsJson) {
    const existing = await prisma.billingAdjustment.findFirst({
      where: { code: adj.code.toString() },
    });

    if (existing) {
      await prisma.billingAdjustment.update({
        where: { id: existing.id },
        data: {
          name: adj.description,
        },
      });
    } else {
      await prisma.billingAdjustment.create({
        data: {
          code: adj.code.toString(),
          name: adj.description,
        },
      });
    }
  }

  return adjustmentsJson.length;
}
