import type {
  BillingPayer,
  BillingTransactionAllocation,
  WaystarInsuranceProvider,
} from "@procision-software/database";
import { Prisma, type PrismaClient } from "@procision-software/database";
import {
  organizationIdToString,
  type AppAbility,
  type OrganizationId,
} from "@procision-software/auth";
import type { PaginationInput } from "@procision-software/database-zod";
import type { PaginatedResult } from "~/types/paginated-result";
import type { BillingAmounts } from "../models/claim";
import { augmentClaimWithBillingAmounts } from "../models/claim";
import { payerName } from "../models/payers";

const sql = (organizationId: OrganizationId, patientId?: string) => Prisma.sql`
with "PatientResponsibilityAdjustments" as (
  select "id" from "BillingAdjustment"
)
select
"BillingCharge"."id" "billingChargeId",
"BillingCase"."id" "billingCaseId",
sum("BillingTransactionAllocation".amount) as paid,
"BillingCharge"."billedAmount" as billed,
"BillingCharge"."billedAmount" - sum("BillingTransactionAllocation".amount) as outstanding
from
"BillingCase"
inner join "BillingCharge" on "BillingCharge"."billingCaseId" = "BillingCase"."id"
inner join "BillingTransactionAllocation" on "BillingTransactionAllocation"."billingChargeId" = "BillingCharge"."id"
inner join "Case" on "Case"."id" = "BillingCase"."caseId"
inner join "Facility" on "Facility"."id" = "Case"."facilityId"
WHERE
"Facility"."organizationId" = ${organizationIdToString(organizationId)}
and ("BillingTransactionAllocation"."adjustmentGroup" IS NULL OR "BillingTransactionAllocation"."adjustmentGroup" <> 'PR')
${patientId ? Prisma.sql`and "Case"."patientId" = ${patientId}` : Prisma.raw("")}
group by "BillingCharge".id, "BillingCase"."id"
having sum("BillingTransactionAllocation".amount) > "BillingCharge"."billedAmount"`;

type DataRow = {
  billingChargeId: string;
  billingCaseId: string;
  paid: number;
  billed: number;
  outstanding: number;
};

const getClaims = (prisma: PrismaClient, charges: DataRow[]) =>
  prisma.billingClaim.findMany({
    where: {
      billingCaseId: {
        in: charges.map((charge) => charge.billingCaseId),
      },
      billingPayer: {
        paymentType: "Self_Pay",
      },
    },
    include: {
      billingPayer: {
        include: {
          waystarInsuranceProvider: true,
        },
      },
      billingCase: {
        include: {
          case: true,
          billingCharges: {
            include: {
              allocations: true,
            },
          },
        },
      },
    },
  });

type OverpaidClaim = Awaited<ReturnType<typeof getClaims>>[number] & {
  allocations: BillingTransactionAllocation[];
  sequenceNumber: number;
  payer: BillingPayer & { name: string; waystarInsuranceProvider: WaystarInsuranceProvider | null };
  claimFormat: "Paper";
  claimType: "Offline";
  dateOfService: Date;
} & BillingAmounts;

export async function selfPayOverPayments(
  prisma: PrismaClient,
  ability: AppAbility,
  organizationId: OrganizationId,
  pagination: PaginationInput,
  patientId?: string
): Promise<PaginatedResult<OverpaidClaim>> {
  const query = sql(organizationId, patientId);
  const data = Prisma.sql`${query} LIMIT ${pagination.perPage} OFFSET ${pagination.perPage * (pagination.page - 1)}`;
  const count = await prisma.$queryRaw<{ count: number }[]>(
    Prisma.sql`SELECT count(*) as count FROM (${query}) rows`
  );
  const charges = await prisma.$queryRaw<DataRow[]>(data);
  const claims = await getClaims(prisma, charges);
  return {
    rows: (
      await Promise.all(
        charges.map(async (charge) => {
          const claim = claims.find((claim) => claim.billingCaseId === charge.billingCaseId)!;
          if (!claim) return null;
          const augmentedClaim = await augmentClaimWithBillingAmounts(prisma, claim);
          return {
            ...claim,
            allocations: [],
            billedAmount: augmentedClaim.billedAmount,
            priorPayersAmount: augmentedClaim.priorPayersAmount,
            expectedAmount: augmentedClaim.expectedAmount,
            adjustmentAmount: augmentedClaim.adjustmentAmount,
            paymentAmount: augmentedClaim.paymentAmount,
            outstandingAmount: augmentedClaim.outstandingAmount,
            sequenceNumber: Number(claim.billingPayer.sequenceNumber),
            payer: {
              ...claim.billingPayer,
              name: await payerName(prisma, ability, claim.billingPayer),
            },
            claimFormat: "Paper",
            claimType: "Offline",
            dateOfService: claim.billingCase.case.surgeryDate,
            officeOnlyCharges: augmentedClaim.officeOnlyCharges,
          };
        })
      )
    )
      .filter((v) => !!v)
      .map((v) => v as NonNullable<OverpaidClaim>),
    pagination: {
      page: pagination.page,
      perPage: pagination.perPage,
      all: Number(count[0]!.count),
    },
  };
}
