/* eslint-disable @next/next/no-img-element */
import { zodResolver } from "@hookform/resolvers/zod";
import type { JobRoleType } from "@procision-software/database-zod";
import {
  PinMismatchError,
  StaffSignatureAuthFormDataSchema,
  type StaffSignatureAuthFormData,
} from "@procision-software/mason";
import { Button, Field, Fieldset, Form, InputField } from "@procision-software/ui";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { StaffSelect } from "~/components/fields/StaffSelect";

type StaffSignatureSignFormProps = {
  blob: string;
  defaultValues: Partial<StaffSignatureAuthFormData>;
  hideStaffSelect?: boolean;
  isPinRequired: boolean;
  jobRoleType?: JobRoleType;
  onCancel: () => unknown;
  onChangeStaff: (id: string | null) => void;
  onSubmit: (data: StaffSignatureAuthFormData) => unknown;
  staffId?: string;
};

export function StaffSignatureSignForm({
  blob,
  defaultValues,
  hideStaffSelect,
  isPinRequired,
  jobRoleType,
  onCancel,
  onChangeStaff: onChange,
  onSubmit,
  staffId,
}: StaffSignatureSignFormProps) {
  const isStaffSelectHidden = hideStaffSelect ?? false;

  const form = useForm<StaffSignatureAuthFormData>({
    resolver: zodResolver(StaffSignatureAuthFormDataSchema),
    defaultValues,
  });

  const {
    formState: { isSubmitting },
  } = form;

  const handleSubmit = useCallback(
    async (data: StaffSignatureAuthFormData) => {
      try {
        await onSubmit(data);
      } catch (error) {
        if ((error as Error).message === PinMismatchError.name) {
          form.setError("pin", { message: "Invalid pin." });
        } else {
          throw error;
        }
      }
    },
    [form, onSubmit]
  );

  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      {!isStaffSelectHidden && (
        <Fieldset>
          <Field label="Staff" className="flex-1">
            <StaffSelect
              value={staffId}
              blankOption={true}
              context={{ activeOnly: true }}
              onChange={onChange}
              roleType={jobRoleType}
              width="full"
            />
          </Field>
        </Fieldset>
      )}
      <Fieldset>
        <Field label="Signature">
          <img src={blob} className="aspect-signature-pad" />
        </Field>
      </Fieldset>
      {isPinRequired && (
        <Fieldset>
          <InputField {...form} label="Pin" name="pin" hideContent />
        </Fieldset>
      )}
      <Fieldset variant="actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant="primary" type="submit" loading={isSubmitting}>
          Apply Signature
        </Button>
      </Fieldset>
    </Form>
  );
}
