import { PermissionDeniedError, type AppAbility } from "@procision-software/auth";
import { Prisma, type PrismaClient } from "@procision-software/database";
import { type BillingChargeMaster, type Organization } from "@procision-software/database-zod";
import { nameForCptCode, type NamedBillingChargeMaster } from "@procision-software/mason";
import { type PaginatedResult } from "~/types/paginated-result";

export async function duplicateChargeMasters(
  prisma: PrismaClient,
  ability: AppAbility,
  input: { sort: string; order: "asc" | "desc"; page: number; perPage: number; search?: string }
): Promise<
  PaginatedResult<
    NamedBillingChargeMaster & { organization: Organization & { billingOrganizationId: string } }
  >
> {
  const orderBy: Prisma.BillingChargeMasterOrderByWithRelationInput[] = [
    { active: "desc" },
    { [input.sort]: input.order },
  ];
  if (input.sort === "cptCode") {
    orderBy.push({ cptCode: input.order });
  }
  const where: Prisma.BillingChargeMasterWhereInput = {
    ...(input.search && {
      cptCode: {
        contains: input.search.toUpperCase(),
      },
    }),
  };
  const duplicates = await prisma.$queryRaw<
    { cptCode: string; hcpcsCode: string; billingOrganizationId: string }[]
  >(
    Prisma.sql`select "cptCode", "hcpcsCode", "billingOrganizationId" from "BillingChargeMaster" where "active"=true group by "cptCode", "hcpcsCode", "billingOrganizationId" having count(*) > 1`
  );
  if (duplicates.length === 0)
    return {
      rows: [],
      pagination: {
        page: input.page,
        perPage: input.perPage,
        all: 0,
      },
    };
  where.AND = [
    {
      OR: duplicates.map(({ cptCode, hcpcsCode, billingOrganizationId }) => ({
        cptCode,
        hcpcsCode,
        billingOrganizationId,
      })),
    },
  ];
  const rows = await prisma.billingChargeMaster.findMany({
    where,
    include: {
      billingOrganization: {
        include: {
          organization: true,
        },
      },
    },
    orderBy,
    skip: (input.page - 1) * input.perPage,
    take: input.perPage,
  });
  return {
    rows: (await augmentChargeMasterWithProcedureName(prisma, ability, rows)).map(
      ({ billingOrganization, ...row }) => {
        return {
          ...row,
          organization: {
            ...billingOrganization.organization,
            billingOrganizationId: billingOrganization.id,
          },
        };
      }
    ),
    pagination: {
      page: input.page,
      perPage: input.perPage,
      all: await prisma.billingChargeMaster.count({
        where,
      }),
    },
  };
}

export async function listChargeMaster(
  prisma: PrismaClient,
  ability: AppAbility,
  input: { sort: string; order: "asc" | "desc"; page: number; perPage: number; search?: string }
): Promise<
  PaginatedResult<
    NamedBillingChargeMaster & { organization: Organization & { billingOrganizationId: string } }
  >
> {
  const orderBy: Prisma.BillingChargeMasterOrderByWithRelationInput[] = [
    { active: "desc" },
    { [input.sort]: input.order },
  ];
  if (input.sort === "cptCode") {
    orderBy.push({ cptCode: input.order });
  }
  const where = {
    ...(input.search && {
      cptCode: {
        contains: input.search.toUpperCase(),
      },
    }),
  };
  const rows = await prisma.billingChargeMaster.findMany({
    where,
    include: {
      billingOrganization: {
        include: {
          organization: true,
        },
      },
    },
    orderBy,
    skip: (input.page - 1) * input.perPage,
    take: input.perPage,
  });
  return {
    rows: (await augmentChargeMasterWithProcedureName(prisma, ability, rows)).map(
      ({ billingOrganization, ...row }) => {
        return {
          ...row,
          organization: {
            ...billingOrganization.organization,
            billingOrganizationId: billingOrganization.id,
          },
        };
      }
    ),
    pagination: {
      page: input.page,
      perPage: input.perPage,
      all: await prisma.billingChargeMaster.count({
        where,
      }),
    },
  };
}

export async function augmentChargeMasterWithProcedureName<T extends BillingChargeMaster>(
  prisma: PrismaClient,
  ability: AppAbility,
  rows: T[]
): Promise<(T & { name: string })[]> {
  const cptCodes = await prisma.cptCode.findMany({
    where: {
      code: {
        in: rows.filter((cpt) => cpt.cptCode !== null).map((row) => row.cptCode),
      },
    },
  });
  return rows.map((row) => ({
    ...row,
    name: cptCodes.find((cpt) => cpt.code === row.cptCode)?.name ?? "Unknown Code",
  }));
}

export async function getChargeMasterForId(
  prisma: PrismaClient,
  id: string
): Promise<NamedBillingChargeMaster> {
  const cm = await prisma.billingChargeMaster.findFirstOrThrow({
    where: {
      id,
    },
  });
  const name = await nameForCptCode(prisma, cm.cptCode);
  return { ...cm, name };
}

// Update ChargeMaster
export async function updateChargeMaster(
  prisma: PrismaClient,
  ability: AppAbility,
  id: string,
  data: Prisma.BillingChargeMasterUpdateInput
) {
  if (!ability.can("update", "BillingChargeMaster")) {
    throw new PermissionDeniedError();
  }
  await prisma.billingChargeMaster.updateMany({
    where: {
      id,
    },
    data,
  });
  return await prisma.billingChargeMaster.findFirstOrThrow({
    where: {
      id,
    },
  });
}

// Delete ChargeMaster
export async function deleteChargeMaster(prisma: PrismaClient, ability: AppAbility, id: string) {
  if (!ability.can("delete", "BillingChargeMaster")) {
    throw new PermissionDeniedError();
  }
  const deleted = await prisma.billingChargeMaster.updateMany({
    where: {
      id,
    },
    data: {
      active: false,
    },
  });
  return deleted.count === 1;
  // Prisma Documentation: https://www.prisma.io/docs/reference/api-reference/prisma-client-reference#delete
}

export async function typeOfCode(
  prisma: Pick<PrismaClient, "cptCode">,
  code: string
): Promise<"hcpcs" | "cpt" | null> {
  const cptCode = await prisma.cptCode.findFirst({ where: { code } });

  if (!cptCode) {
    return null;
  }

  if (cptCode.kind === "CPT") {
    return "cpt";
  }

  return "hcpcs";
}
