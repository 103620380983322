import React, { forwardRef } from "react";
import { trpc } from "~/utils/trpc";
import {
  Select,
  SelectMultiple,
  type SelectMultipleProps,
  type SelectProps,
} from "@procision-software/ui";
import { type JobRoleType } from "@procision-software/database-zod";
import { type StaffFilterContext } from "@procision-software/mason";
import { staffFullName } from "@procision-software/mason";

export const StaffSelect = forwardRef<
  HTMLButtonElement,
  Omit<SelectProps<string>, "value" | "options" | "onChange"> & {
    roleType?: JobRoleType;
    context?: StaffFilterContext;
    sort?: "lastName" | undefined;
    blankOption?: boolean;
    value: string | null | undefined;
    onChange: (id: string | null) => unknown;
  }
>(({ roleType, context, sort, disabled, blankOption, value, ...props }, ref) => {
  const partialContext = context
    ? {
        practiceId: context.practiceId,
        facilityId: context.facilityId,
        activeOnly: context.activeOnly ?? true,
      }
    : undefined;
  const staff = trpc.staff.list.useQuery({ roleType, sort, context: partialContext });
  return (
    <Select
      width="full"
      includeBlankValue={blankOption ? "" : undefined}
      {...props}
      onChange={(id) => props?.onChange(id === "" ? null : id)}
      ref={ref}
      value={value ?? undefined}
      disabled={disabled || staff.isLoading}
      options={staff.data?.map((staff) => ({ value: staff.id, label: staffFullName(staff) })) ?? []}
    />
  );
});
StaffSelect.displayName = "StaffSelect";

export const StaffSelectMultiple = forwardRef<
  HTMLButtonElement,
  Omit<SelectMultipleProps<string>, "options"> & {
    roleType?: JobRoleType;
    context?: StaffFilterContext;
  }
>(({ roleType, context, disabled, ...props }, ref) => {
  const partialContext = context
    ? {
        practiceId: context.practiceId,
        facilityId: context.facilityId,
        activeOnly: context.activeOnly ?? true,
      }
    : undefined;
  const staff = trpc.staff.list.useQuery({ roleType, context: partialContext });
  return (
    <SelectMultiple
      width="full"
      options={staff.data?.map((staff) => ({ value: staff.id, label: staffFullName(staff) })) ?? []}
      disabled={disabled || staff.isLoading}
      renderSelection={(selected) => selected.map((s) => s.label).join(", ")}
      ref={ref}
      {...props}
    />
  );
});
StaffSelectMultiple.displayName = "StaffSelectMultiple";
